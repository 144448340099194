import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import AboutV4 from "./section-components/about-v4";
import Features from "./section-components/features-v1";
import Team from "./section-components/team-v1";
import Testimonial from "./section-components/testimonial-v1";
import BlogSlider from "./blog-components/blog-slider-v1";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import ServiceV1 from "./section-components/service-v1";
import WhyChooseUs from "./section-components/why-choose-us";
import AboutV3 from "./section-components/about-v3";
import { Helmet } from "react-helmet";

const About_v1 = () => {
  const Curl = window.location.href;
  return (
    <>
      <Helmet>
        <title>About Us - Lee group</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Lee Group Properties is committed to offering a quick service while looking for a house. We are here to help you fulfil your housing goals. Enquire us."
        />
        <meta
          name="keywords"
          content="Property Dealers in Coimbatore, Real estate, Realty ,Residential real estate  Coimbatore"
        />
        <meta property="og:title" content="About Us - Lee group" />
        <meta
          property="og:description"
          content="Lee Group Properties is committed to offering a quick service while looking for a house. We are here to help you fulfil your housing goals. Enquire us."
        />
        <meta property="og:url" content={Curl} />
        <meta property="og:site_name" content="Lee Gorup" />
        <link rel="canonical" href={Curl} />
      </Helmet>
      <div>
        <Navbar />
        <PageHeader headertitle="About Us" />
        <AboutV4 />
        <ServiceV1 />
        {/* <Features  customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---"/> */}
        <AboutV3 />
        <WhyChooseUs />
        <Testimonial />
        {/* <BlogSlider /> */}
        <CallToActionV1 />
        <Footer />
      </div>
    </>
  );
};

export default About_v1;
