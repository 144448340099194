import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import PortfolioV1 from "./section-components/portfolio-v1";
import CallToActonV2 from "./section-components/call-to-action-v2";
import BlogSlider from "./blog-components/blog-slider-v1";
import Sponsor from "./section-components/sponsor-v2";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import { Helmet } from "react-helmet";

const Portfolio_V1 = () => {
  const Curl = window.location.href;
  return (
    <>
      <Helmet>
        <title>Gallery - Commercial property for sale in coimbatore </title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="
          Before visiting our site, click us to check our site gallery and make your decision. We are offer the greatest assistance and resources to assist your home attempt be a success."
        />
        <meta
          name="keywords"
          content="Best Real Estate Photos,Luxury Villas in Coimbatore, Villa Plots in Coimbatore , Residential property in Coimbatore."
        />
        <meta
          property="og:title"
          content="Gallery - Commercial property for sale in coimbatore "
        />
        <meta
          property="og:description"
          content="
          Before visiting our site, click us to check our site gallery and make your decision. We are offer the greatest assistance and resources to assist your home attempt be a success."
        />
        <meta property="og:url" content={Curl} />
        <meta property="og:site_name" content="Lee Gorup" />
        <link rel="canonical" href={Curl} />
      </Helmet>
      <div>
        <Navbar />
        <PageHeader headertitle="Gallery" subheader="Gallery" />
        <PortfolioV1 />

        <CallToActionV1 />
        <Footer />
      </div>
    </>
  );
};

export default Portfolio_V1;
