import React, { Component } from "react";
import { Link } from "react-router-dom";
//import parse from "html-react-parser";

const ServiceV5 = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="ltn__service-area section-bg-1 pt-115 pb-70 go-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                Our Features
              </h6>
              <h2 className="section-title">Our Features</h2>
            </div>
          </div>
        </div>
        <div className="row  justify-content-center">
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
              <div className="ltn__feature-icon">
                <span>
                  <i className="flaticon-house" />
                </span>
              </div>
              <div className="ltn__feature-info">
                <h3>
                  <Link to="#">Premium Real Estate Solutions</Link>
                </h3>
                <p>
                  Our knowledgeable team of agents is here to make your dream
                  home or property a reality. With years of experience and
                  in-depth market knowledge, you can trust us to find the
                  perfect fit for you.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
              <div className="ltn__feature-icon">
                <span>
                  <i className="flaticon-apartment" />
                </span>
              </div>
              <div className="ltn__feature-info">
                <h3>
                  <Link to="#">Architecture & Interior Design Services</Link>
                </h3>
                <p>
                  We offer complete architecture and interior design services
                  with expert guidance on how to optimize your space for maximum
                  comfort and style. Our experienced designers can help you
                  bring your vision to life!
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
              <div className="ltn__feature-icon">
                <span>
                  <i className="flaticon-official-documents" />
                </span>
              </div>
              <div className="ltn__feature-info">
                <h3>
                  <Link to="#">Property Management Services</Link>
                </h3>
                <p>
                  Our team of dedicated property managers will ensure that all
                  aspects of managing your property are taken care of with
                  strict attention to detail. We specialize in both residential
                  and commercial properties.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
              <div className="ltn__feature-icon">
                <span>
                  <i className="flaticon-house-4" />
                </span>
              </div>
              <div className="ltn__feature-info">
                <h3>
                  <Link to="#">Affordable & Professional Services</Link>
                </h3>
                <p>
                  We strive to provide our clients with the best possible value
                  for money when it comes to our services. Our competitive
                  pricing ensures that you get access to the best real estate
                  solutions without breaking the bank.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
              <div className="ltn__feature-icon">
                <span>
                  <i className="flaticon-house-1" />
                </span>
              </div>
              <div className="ltn__feature-info">
                <h3>
                  <Link to="#">A comprehensive service</Link>
                </h3>
                <p>
                  We offer a comprehensive real estate service, covering
                  residential sales, rentals, architecture, interior design and
                  property management as well as commercial sales and leasing.
                  Our experienced team of experts have years of combined
                  experience in the industry.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
              <div className="ltn__feature-icon">
                <span>
                  <i className="flaticon-operator" />
                </span>
              </div>
              <div className="ltn__feature-info">
                <h3>
                  <Link to="#">
                    Unparalleled <br />
                    expertise
                  </Link>
                </h3>
                <p>
                  Our experienced agents have unparalleled expertise in the
                  local market, allowing us to provide you with the best advice
                  on buying or renting a property that meets your needs. We also
                  offer services such as architectural/interior design to ensure
                  your home is exactly what you're looking for.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
              <div className="ltn__feature-icon">
                <span>
                  <i className="flaticon-user-1" />
                </span>
              </div>
              <div className="ltn__feature-info">
                <h3>
                  <Link to="#">
                    A stress-free <br />
                    experience
                  </Link>
                </h3>
                <p>
                  At Lee Group Properties we strive to make your experience as
                  stress-free as possible by taking care of all the details for
                  you. From finding potential properties to handling
                  negotiations and paperwork, our team has got you covered every
                  step of the way.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
              <div className="ltn__feature-icon">
                <span>
                  <i className="flaticon-apartment-1" />
                </span>
              </div>
              <div className="ltn__feature-info">
                <h3>
                  <Link to="#">Commercial Solutions Tailored to You</Link>
                </h3>
                <p>
                  If you are looking for commercial solutions, The Lee Group has
                  you covered. Our team is experienced in commercial sales and
                  leasing and can provide customized solutions tailored to your
                  specific needs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
              <div className="ltn__feature-icon">
                <span>
                  <i className="flaticon-building" />
                </span>
              </div>
              <div className="ltn__feature-info">
                <h3>
                  <Link to="#">Making Your Dreams a Reality</Link>
                </h3>
                <p>
                  Our experienced and knowledgeable team of real estate
                  professionals will help you find the perfect home that meets
                  your needs and fits your budget. We specialize in residential
                  sales, rentals, architecture, interior decoration, and
                  property management.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceV5;
