import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class WhyChooseUs extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__feature-area section-bg-2 pt-115 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Features
                </h6>
                <h3 className="section-title">Why Choose Us</h3>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-7 ltn__feature-item-7-color-white val-ht">
                <div className="ltn__feature-icon-title">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="flaticon-house-4" />
                    </span>
                  </div>
                  <h3>
                    <a href="#">Integrity</a>
                  </h3>
                </div>
                <div className="ltn__feature-info">
                  <p>
                    Honesty, transparency, and ethical behavior in all business
                    dealings are our key path of Integrity. By being forthright
                    about any potential conflicts of interest, and by adhering
                    to all applicable laws and regulations, we prevail as a top
                    real estate company.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-7 ltn__feature-item-7-color-white val-ht">
                <div className="ltn__feature-icon-title">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="icon-mechanic" />
                    </span>
                  </div>
                  <h3>
                    <a href="#">Customer focus</a>
                  </h3>
                </div>
                <div className="ltn__feature-info">
                  <p>
                    We focus on putting the needs and wants of the client at the
                    forefront of all business decisions. We prioritize your
                    communication by listening attentively to client concerns
                    and tailor our services to meet the specific needs of
                    everyone.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-7 ltn__feature-item-7-color-white val-ht">
                <div className="ltn__feature-icon-title">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="icon-repair-1" />
                    </span>
                  </div>
                  <h3>
                    <a href="#">Expertise</a>
                  </h3>
                </div>
                <div className="ltn__feature-info">
                  <p>
                    Our company deeply understands the real estate market and
                    the skills necessary to effectively navigate it. Our team
                    comprises of experienced and knowledgeable agents who can
                    provide informed advice on pricing, negotiations, and other
                    important aspects of the buying and selling process.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WhyChooseUs;
