import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import {
  GiConcreteBag,
  GiDoubleStreetLights,
  GiCableStayedBridge,
  GiCctvCamera,
} from "react-icons/gi";
import { ImRoad } from "react-icons/im";
import { TbTrees } from "react-icons/tb";
import { FaHandHoldingWater } from "react-icons/fa";

class CategoryV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div className="ltn__category-area ltn__product-gutter section-bg-1--- pt-115 pb-90 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Our Aminities
                </h6>
                <h2 className="section-title">Enhanced Aminities</h2>
              </div>
            </div>
          </div>
          <div className="row ltn__category-slider-active--- slick-arrow-1 justify-content-center">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 text-center">
                <Link to="#">
                  <span className="category-icon">
                    <GiConcreteBag />
                  </span>
                  <span className="category-title">Concrete Drains</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 text-center">
                <Link to="#">
                  <span className="category-icon">
                    <TbTrees />
                  </span>
                  <span className="category-title">Avenue Trees</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 text-center">
                <Link to="#">
                  <span className="category-icon">
                    <GiDoubleStreetLights />
                  </span>
                  <span className="category-title">Street Lights</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 text-center">
                <Link to="#">
                  <span className="category-icon">
                    <ImRoad />
                  </span>
                  <span className="category-title">Concrete Roads</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 text-center">
                <Link to="#">
                  <span className="category-icon">
                    <i className="flaticon-slider" />
                  </span>
                  <span className="category-title">Parks & Play Grounds</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 text-center">
                <Link to="#">
                  <span className="category-icon">
                    <GiCableStayedBridge />
                  </span>
                  <span className="category-title">Underground cabling</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 text-center">
                <Link to="#">
                  <span className="category-icon">
                    <GiCctvCamera />
                  </span>
                  <span className="category-title">
                    Secure Gated Entry & Exit
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 text-center">
                <Link to="#">
                  <span className="category-icon">
                    <FaHandHoldingWater />
                  </span>
                  <span className="category-title">Rainwater harvesting</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CategoryV1;
