import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import BlogGrid from "./blog-components/blog-grid";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import { Helmet } from "react-helmet";

const BlogGridPage = () => {
  const Curl = window.location.href;
  return (
    <>
      <Helmet>
        <title>Coimbatore real estate industry news - Lee group </title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Keep up with the latest real estate news with our cutting-edge blogs. We show you how to select the greatest real estate within your price range."
        />
        <meta
          name="keywords"
          content="Coimbatore news, Real estate services in Coimbatore,
          Real estate prices in Coimbatore , Real estate blogs,Real Estate Market Trends in Coimbatore"
        />
        <meta
          property="og:title"
          content="Coimbatore real estate industry news - Lee group "
        />
        <meta
          property="og:description"
          content="Keep up with the latest real estate news with our cutting-edge blogs. We show you how to select the greatest real estate within your price range."
        />
        <meta property="og:url" content={Curl} />
        <meta property="og:site_name" content="Lee Gorup" />
        <link rel="canonical" href={Curl} />
      </Helmet>
      <div>
        <Navbar />
        <PageHeader headertitle="News & Blog" />
        <BlogGrid />
        <CallToActionV1 />
        <Footer />
      </div>
    </>
  );
};

export default BlogGridPage;
