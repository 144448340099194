import React from "react";
import { useState } from "react";
import swal from "sweetalert";

const FooterForm = () => {
  const [inputVal, setInputVal] = useState({});
  const [loading, setloading] = useState(true);

  const OnchangeVal = (event) => {
    setInputVal({ ...inputVal, [event.target.name]: event.target.value });
  };

  const FooterSub = (e) => {
    e.preventDefault();
    e.target.reset();

    setloading(false);
    window.Email.send({
      Host: "smtp.elasticemail.com",
      Username: "contact@leegroup.in",
      Password: "54438BD6520B636C0FC9EAA221702133E3E7",
      To: "contact@leegroup.in",
      From: "bluemount251095@gmail.com",
      Subject: "Lee Group - Newsletter Form",
      Body: `<b>Email :</b> ${inputVal.email} `,
    }).then((message) => {
      console.log(message);
      if (message === "OK") {
        swal({
          title: "Email Sent Successfully",
          text: "Thank you! We've received your message. Someone from our team will contact you soon.",
          icon: "success",
          button: "OK",
        });
        setloading(true);
      } else {
        swal({
          title: "Something Error!",
          text: "Please try aftor sometime",
          icon: "error",
          button: "Ok",
        });
        setloading(true);
      }
    });
  };
  return (
    <>
      <div className="footer-widget footer-newsletter-widget">
        <h4 className="footer-title">Newsletter</h4>
        <p>Subscribe to our weekly Newsletter and receive updates via email.</p>
        <div className="footer-newsletter">
          <form onSubmit={FooterSub} method="post">
            <input
              type="email"
              name="email"
              placeholder="Email*"
              required
              onChange={OnchangeVal}
            />
            <div className="btn-wrapper">
              <button className="theme-btn-1 btn" type="submit">
                <i className="fas fa-location-arrow" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FooterForm;
