import React, { Component } from "react";

class CounterV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__counterup-area section-bg-1 pt-120 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-6 align-self-center">
              <div className="ltn__counterup-item text-color-white---">
                <div className="counter-icon">
                  <i className="flaticon-office" />
                </div>
                <h3>
                  <span className="counter">220</span>
                  <span className="counterUp-icon">+</span>{" "}
                </h3>
                <h6>Plots</h6>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 align-self-center">
              <div className="ltn__counterup-item text-color-white---">
                <div className="counter-icon">
                  <i className="flaticon-house-1" />
                </div>
                <h3>
                  <span className="counter">323</span>
                  <span className="counterUp-icon">+</span>{" "}
                </h3>
                <h6>Villas</h6>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 align-self-center">
              <div className="ltn__counterup-item text-color-white---">
                <div className="counter-icon">
                  <i className="flaticon-excavator" />
                </div>
                <h3>
                  <span className="counter">440</span>
                  <span className="counterUp-icon">+</span>{" "}
                </h3>
                <h6>Constructions</h6>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 align-self-center">
              <div className="ltn__counterup-item text-color-white---">
                <div className="counter-icon">
                  <i className="flaticon-user-1" />
                </div>
                <h3>
                  <span className="counter">723</span>
                  <span className="counterUp-icon">+</span>{" "}
                </h3>
                <h6>Happy customer</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CounterV1;
