import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import sanityClient from "../../lib/client";

const RelatedProduct = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [project, setProject] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'project'][0..3]{
          title,
          slug,
          smalldes,
          mainImage{
            asset->{
              _id,
              url
             }
           },
          
       }`
      )
      .then((data) => setProject(data))
      .catch(console.error);
  }, []);
  return (
    <>
      <div className="widget ltn__top-rated-product-widget go-top">
        <h4 className="ltn__widget-title ltn__widget-title-border-2">
          Related Product
        </h4>
        <ul>
          {project &&
            project.map((pro, index) => (
              <li key={index}>
                <div className="top-rated-product-item clearfix">
                  <div className="top-rated-product-img">
                    <Link to={`/project/${pro.slug.current}`}>
                      <img
                        style={{ borderRadius: "5px" }}
                        src={pro.mainImage.asset.url}
                        alt="#"
                      />
                    </Link>
                  </div>
                  <div className="top-rated-product-info">
                    <h6>
                      <Link to={`/project/${pro.slug.current}`}>
                        {pro.title}
                      </Link>
                    </h6>
                    <div className="product-price">
                      <span>
                        {" "}
                        <Link to={`/project/${pro.slug.current}`}>
                          Read more
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default RelatedProduct;
