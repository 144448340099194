import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../../lib/client";
import StackGrid, { transitions } from "react-stack-grid";
import sizeMe from "react-sizeme";

const ShopGridV1 = ({ size }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [project, setProject] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'project']{
          title,
          slug,
          smalldes,
          mainImage{
            asset->{
              _id,
              url
             }
           },
           imageGallery[]{
            asset->{
              _id,
              url
             }
           },
           textloc,
           publishedAt,
           location,
         body,
        
       }`
      )
      .then((data) => setProject(data))
      .catch(console.error);
  }, []);
  if (!project)
    return (
      <div>
        <h2 className="txt-center">Loading...</h2>
      </div>
    );
  return (
    <div>
      <div className="ltn__product-area ltn__product-gutter mb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tab-content ">
                <div
                  className="tab-pane fade active show"
                  id="liton_product_grid"
                >
                  <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                    <div className="row">
                      {size.width <= 750 ? (
                        <>
                          <StackGrid
                            columnWidth={size.width <= 750 ? "100%" : "33.33%"}
                            appearDelay={0}
                            monitorImagesLoaded={true}
                            vendorPrefix={true}
                            gutterHeight={7}
                            itemComponent="article"
                            component="section"
                          >
                            {/* ltn__product-item */}
                            {project &&
                              project.map((pro, index) => (
                                <div className="pad-lr-15" key={index}>
                                  <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                                    <div className="product-img">
                                      <Link to={`/project/${pro.slug.current}`}>
                                        <img
                                          src={pro.mainImage.asset.url}
                                          alt="#"
                                        />
                                      </Link>
                                    </div>

                                    <div
                                      className="product-info"
                                      style={{ padding: "30px 30px" }}
                                    >
                                      <div className="product-badge">
                                        <ul>
                                          <li className="sale-badg">
                                            For Sales
                                          </li>
                                        </ul>
                                      </div>
                                      <h1 className="product-title go-top">
                                        <Link
                                          to={`/project/${pro.slug.current}`}
                                        >
                                          {pro.title}
                                        </Link>
                                      </h1>
                                      <div
                                        className="product-img-location"
                                        style={{ paddingBottom: "15px" }}
                                      >
                                        <ul>
                                          <li className="go-top">
                                            <Link
                                              to={`/project/${pro.slug.current}`}
                                            >
                                              <i className="flaticon-pin" />{" "}
                                              {pro.textloc}
                                            </Link>
                                          </li>
                                        </ul>
                                      </div>

                                      <div className="product-description">
                                        <p>{pro.smalldes}</p>
                                      </div>
                                      <div className="btn-wrapper go-top">
                                        <Link
                                          className="btn btn-effect-3 btn-white"
                                          to={`/project/${pro.slug.current}`}
                                        >
                                          More Details{" "}
                                          <i className="icon-next" />
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </StackGrid>
                        </>
                      ) : (
                        <>
                          <StackGrid
                            columnWidth={size.width <= 1000 ? "50%" : "33.33%"}
                            appearDelay={0}
                            monitorImagesLoaded={true}
                            vendorPrefix={true}
                            gutterHeight={7}
                            itemComponent="article"
                            component="section"
                          >
                            {/* ltn__product-item */}
                            {project &&
                              project.map((pro, index) => (
                                <div className="pad-lr-15" key={index}>
                                  <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                                    <div className="product-img">
                                      <Link to={`/project/${pro.slug.current}`}>
                                        <img
                                          src={pro.mainImage.asset.url}
                                          alt="#"
                                        />
                                      </Link>
                                    </div>

                                    <div
                                      className="product-info"
                                      style={{ padding: "30px 30px" }}
                                    >
                                      <div className="product-badge">
                                        <ul>
                                          <li className="sale-badg">
                                            For Sales
                                          </li>
                                        </ul>
                                      </div>
                                      <h1 className="product-title go-top">
                                        <Link
                                          to={`/project/${pro.slug.current}`}
                                        >
                                          {pro.title}
                                        </Link>
                                      </h1>
                                      <div
                                        className="product-img-location"
                                        style={{ paddingBottom: "15px" }}
                                      >
                                        <ul>
                                          <li className="go-top">
                                            <Link
                                              to={`/project/${pro.slug.current}`}
                                            >
                                              <i className="flaticon-pin" />{" "}
                                              {pro.textloc}
                                            </Link>
                                          </li>
                                        </ul>
                                      </div>

                                      <div className="product-description">
                                        <p>{pro.smalldes}</p>
                                      </div>
                                      <div className="btn-wrapper go-top">
                                        <Link
                                          className="btn btn-effect-3 btn-white"
                                          to={`/project/${pro.slug.current}`}
                                        >
                                          More Details{" "}
                                          <i className="icon-next" />
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </StackGrid>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default sizeMe()(ShopGridV1);
