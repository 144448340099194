import React, { useEffect, useState } from "react";
import swal from "sweetalert";

function Modelpopupform() {
  const [openM, setOpenM] = useState(false);
  const [inputVal, setInputVal] = useState({});
  const [loading, setloading] = useState(true);

  const OnchangeVal = (event) => {
    setInputVal({ ...inputVal, [event.target.name]: event.target.value });
  };
  const OnsubmitEnquiry = (e) => {
    e.preventDefault();
    var phoneNo = inputVal.phone;
    var phoneVal = /^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/;
    if (!phoneNo.match(phoneVal)) {
      alert("Please provide valid phone number");
    } else {
      e.target.reset();

      setloading(false);
      window.Email.send({
        Host: "smtp.elasticemail.com",
        Username: "contact@leegroup.in",
        Password: "54438BD6520B636C0FC9EAA221702133E3E7",
        To: "contact@leegroup.in",
        From: "bluemount251095@gmail.com",
        Subject: "Lee Group - Enquiry Form",
        Body: `<b>Name :</b> ${inputVal.name} <br/><br/> <b>Phone No :</b> ${inputVal.phone} <br/><br/> <b>Property Type : </b>${inputVal.bh} <br/><br/> <b>Message : </b>${inputVal.msg} `,
      }).then((message) => {
        console.log(message);
        if (message === "OK") {
          swal({
            title: "Email Sent Successfully",
            text: "Thank you! We've received your message. Someone from our team will contact you soon.",
            icon: "success",
            button: "OK",
          });
          setloading(true);
        } else {
          swal({
            title: "Something Error!",
            text: "Please try aftor sometime",
            icon: "error",
            button: "Ok",
          });
          setloading(true);
        }
        setOpenM(false);
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setOpenM(true);
    }, 2000);
  }, []);

  return (
    <>
      {openM && (
        <>
          <div className="homeEnq">
            <div className="popup-model">
              <button className="enqCls" onClick={() => setOpenM(false)}>
                <i className="fa fa-close"></i>
              </button>
              <form method="post" onSubmit={OnsubmitEnquiry}>
                <h2 className="enqH2">Lee Group Properties Enquiry</h2>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="name"
                        placeholder="Name*"
                        required
                        onChange={OnchangeVal}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-item input-item-phone ltn__custom-icon">
                      <input
                        type="number"
                        name="phone"
                        placeholder="Mobile No*"
                        required
                        onChange={OnchangeVal}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-item input-item-emaill ltn__custom-icon">
                      <select required onChange={OnchangeVal} name="bh">
                        <option value="">Select Property Type*</option>
                        <option value="1Bh">1Bh</option>
                        <option value="2Bh">2Bh</option>
                        <option value="3Bh">3Bh</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-item input-item-email ltn__custom-icon">
                      <textarea
                        className="enqSele"
                        name="msg"
                        placeholder="Your Message"
                        onChange={OnchangeVal}
                      ></textarea>
                    </div>
                  </div>

                  <div className="btn-wrapper text-center mt-10">
                    <button
                      className="btn theme-btn-1 btn-effect-1 text-uppercase"
                      type="submit"
                    >
                      {loading ? "Submit" : "Wait..."}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Modelpopupform;
