import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../../lib/client";
import { format } from "date-fns";
import StackGrid, { transitions } from "react-stack-grid";
import sizeMe from "react-sizeme";

const BlogGrid = ({ size }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'post']{
            title,
            slug,
            categories,
            mainImage{
              asset->{
                _id,
                url
               }
             },
             publishedAt,
             smalldes,
           body,
          
         }`
      )
      .then((data) => setBlog(data))
      .catch(console.error);
  }, []);
  if (!blog)
    return (
      <div>
        <h2 className="txt-center">Loading...</h2>
      </div>
    );
  return (
    <div className="ltn__blog-area ltn__blog-item-3-normal mb-100 go-top">
      <div className="container">
        <div className="row">
          {size.width <= 750 ? (
            <>
              <StackGrid
                columnWidth={size.width <= 750 ? "100%" : "33.33%"}
                appearDelay={0}
                monitorImagesLoaded={true}
                vendorPrefix={true}
                gutterHeight={7}
                itemComponent="article"
                component="section"
              >
                {/* Blog Item */}
                {blog &&
                  blog.map((blog, index) => (
                    <div className="pad-lr-15" key={index}>
                      <div className="ltn__blog-item ltn__blog-item-3">
                        <div className="ltn__blog-img">
                          <Link to={`/blogs/${blog.slug.current}`}>
                            <img src={blog.mainImage.asset.url} alt="#" />
                          </Link>
                        </div>
                        <div className="ltn__blog-brief">
                          <div className="ltn__blog-meta">
                            <ul>
                              <li className="ltn__blog-author go-top">
                                <Link to={`/blogs/${blog.slug.current}`}>
                                  <i className="far fa-user" />
                                  by: Admin
                                </Link>
                              </li>
                              <li className="ltn__blog-tags go-top">
                                <Link to={`/blogs/${blog.slug.current}`}>
                                  <i className="fas fa-tags" />
                                  {blog.categories}
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <h1 className="ltn__blog-title">
                            <Link to={`/blogs/${blog.slug.current}`}>
                              {blog.title}
                            </Link>
                          </h1>
                          <div className="ltn__blog-meta-btn">
                            <div className="ltn__blog-meta">
                              <ul>
                                <li className="ltn__blog-date">
                                  <i className="far fa-calendar-alt" />
                                  {format(
                                    new Date(blog.publishedAt),
                                    " MMMM, dd, yyyy"
                                  )}
                                  {/* {format(blog.publishedAt, "dd/mm/yyyy")} */}
                                </li>
                              </ul>
                            </div>
                            <div className="ltn__blog-btn">
                              <Link to={`/blogs/${blog.slug.current}`}>
                                Read more
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </StackGrid>
            </>
          ) : (
            <>
              <StackGrid
                columnWidth={size.width <= 1000 ? "50%" : "33.33%"}
                appearDelay={0}
                monitorImagesLoaded={true}
                vendorPrefix={true}
                gutterHeight={7}
                itemComponent="article"
                component="section"
              >
                {/* Blog Item */}
                {blog &&
                  blog.map((blog, index) => (
                    <div className="pad-lr-15" key={index}>
                      <div className="ltn__blog-item ltn__blog-item-3">
                        <div className="ltn__blog-img">
                          <Link to={`/blogs/${blog.slug.current}`}>
                            <img src={blog.mainImage.asset.url} alt="#" />
                          </Link>
                        </div>
                        <div className="ltn__blog-brief">
                          <div className="ltn__blog-meta">
                            <ul>
                              <li className="ltn__blog-author go-top">
                                <Link to={`/blogs/${blog.slug.current}`}>
                                  <i className="far fa-user" />
                                  by: Admin
                                </Link>
                              </li>
                              <li className="ltn__blog-tags go-top">
                                <Link to={`/blogs/${blog.slug.current}`}>
                                  <i className="fas fa-tags" />
                                  {blog.categories}
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <h1 className="ltn__blog-title">
                            <Link to={`/blogs/${blog.slug.current}`}>
                              {blog.title}
                            </Link>
                          </h1>
                          <div className="ltn__blog-meta-btn">
                            <div className="ltn__blog-meta">
                              <ul>
                                <li className="ltn__blog-date">
                                  <i className="far fa-calendar-alt" />
                                  {format(
                                    new Date(blog.publishedAt),
                                    " MMMM, dd, yyyy"
                                  )}
                                  {/* {format(blog.publishedAt, "dd/mm/yyyy")} */}
                                </li>
                              </ul>
                            </div>
                            <div className="ltn__blog-btn">
                              <Link to={`/blogs/${blog.slug.current}`}>
                                Read more
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </StackGrid>
            </>
          )}

          {/*  */}
        </div>
      </div>
    </div>
  );
};

export default sizeMe()(BlogGrid);
