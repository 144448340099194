import React, { useEffect, useState } from "react";
import sanityClient from "../../lib/client";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
//import { Carousel } from "@trendyol-js/react-carousel";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { format } from "date-fns";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const BlogSlider = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [testi, setTesti] = useState([]);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
          title,
          slug,
          categories,
          mainImage{
            asset->{
              _id,
              url
             }
           },
           publishedAt,
           smalldes,
         body,
         
      }`
      )
      .then((data) => setTesti(data))
      .catch(console.error);
  }, []);
  return (
    <div className="ltn__blog-area pt-115--- pb-70 go-top ">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h6 className="section-subtitle ltn__secondary-color section-subtitle-2">
                News &amp; Blogs
              </h6>
              <h2 className="section-title">Leatest News Feeds</h2>
            </div>
          </div>
        </div>
        <div className="row ltn__blog-slider-one-active slick-arrow-1 ltn__blog-item-3-normal slick-initialized slick-slider">
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={80000}
            keyBoardControl={true}
            customTransition="all 0.8"
            slidesToSlide={1}
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            rewindWithAnimation={true}
          >
            {testi.map((tst, index) => (
              <div className="col-lg-12" key={index}>
                <div className="ltn__blog-item ltn__blog-item-3">
                  <div className="ltn__blog-img">
                    <Link to={`/blogs/${tst.slug.current}`}>
                      <img
                        src={tst.mainImage.asset.url}
                        alt="#"
                        style={{ width: "100%", height: "250px" }}
                      />
                    </Link>
                  </div>
                  <div className="ltn__blog-brief">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-author">
                          <Link to={`/blogs/${tst.slug.current}`}>
                            <i className="far fa-user" />
                            by: Admin
                          </Link>
                        </li>
                        <li className="ltn__blog-tags">
                          <Link to={`/blogs/${tst.slug.current}`}>
                            <i className="fas fa-tags" />
                            {tst.categories}
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <h3 className="ltn__blog-title" style={{ height: "90px" }}>
                      <Link to={`/blogs/${tst.slug.current}`}>
                        {tst.title.substr(0, 60) + "..."}
                      </Link>
                    </h3>
                    <div className="ltn__blog-meta-btn">
                      <div className="ltn__blog-meta">
                        <ul>
                          <li className="ltn__blog-date">
                            <i className="far fa-calendar-alt" />
                            {format(
                              new Date(tst.publishedAt),
                              " MMMM, dd, yyyy"
                            )}
                          </li>
                        </ul>
                      </div>
                      <div className="ltn__blog-btn">
                        <Link to={`/blogs/${tst.slug.current}`}>Read more</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>

          {/* <div className="col-lg-12">
                  <div className="ltn__blog-item ltn__blog-item-3">
                    <div className="ltn__blog-img">
                      <Link to="/blog-details">
                        <img
                          src={publicUrl + "assets/img/blog/1.jpg"}
                          alt="#"
                        />
                      </Link>
                    </div>
                    <div className="ltn__blog-brief">
                      <div className="ltn__blog-meta">
                        <ul>
                          <li className="ltn__blog-author">
                            <Link to="#">
                              <i className="far fa-user" />
                              by: Admin
                            </Link>
                          </li>
                          <li className="ltn__blog-tags">
                            <Link to="#">
                              <i className="fas fa-tags" />
                              Interior
                            </Link>
                            <Link to="#">
                              <i className="fas fa-tags" />
                              Decorate
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <h3 className="ltn__blog-title">
                        <Link to="/blog-details">
                          10 Brilliant Ways To Decorate Your Home
                        </Link>
                      </h3>
                      <div className="ltn__blog-meta-btn">
                        <div className="ltn__blog-meta">
                          <ul>
                            <li className="ltn__blog-date">
                              <i className="far fa-calendar-alt" />
                              June 24, 2021
                            </li>
                          </ul>
                        </div>
                        <div className="ltn__blog-btn">
                          <Link to="/blog-details">Read more</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
        </div>
      </div>
    </div>
  );
};

export default BlogSlider;
