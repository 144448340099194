import React, { useState } from "react";

import swal from "sweetalert";

const ContactForm = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [inputVal, setInputVal] = useState({});
  const [loading, setloading] = useState(true);

  const OnchangeVal = (event) => {
    setInputVal({ ...inputVal, [event.target.name]: event.target.value });
  };

  const Onsubmit = (e) => {
    e.preventDefault();
    var phoneNo = inputVal.phone;
    var phoneVal = /^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/;
    if (!phoneNo.match(phoneVal)) {
      alert("Please provide valid phone number");
    } else {
      e.target.reset();
      setloading(false);
      window.Email.send({
        Host: "smtp.elasticemail.com",
        Username: "contact@leegroup.in",
        Password: "54438BD6520B636C0FC9EAA221702133E3E7",
        To: "contact@leegroup.in",
        From: "bluemount251095@gmail.com",
        Subject: "Contact Form - Get A Quote",
        Body: `<b>Name :</b> ${inputVal.name} <br/><br/> <b>Email :</b> ${inputVal.email} <br/><br/> <b>Phone No : </b>${inputVal.phone} <br/><br/> <b>Service Type : </b>${inputVal.service} <br/><br/> <b>Message :</b> ${inputVal.message} `,
      }).then((message) => {
        if (message === "OK") {
          swal({
            title: "Email Sent Successfully",
            text: "Thank you for getting in touch!",
            icon: "success",
            button: "OK",
          });
          setloading(true);
        } else {
          swal({
            title: "Something Error!",
            text: "Please try aftor sometime",
            icon: "error",
            button: "Ok",
          });
          setloading(true);
        }
      });
    }
  };

  return (
    <div className="ltn__contact-message-area mb-120 mb--100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__form-box contact-form-box box-shadow white-bg">
              <h4 className="title-2">Get A Quote</h4>
              <form id="contact-formd" method="post" onSubmit={Onsubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="name"
                        placeholder="Enter your name"
                        required
                        onChange={OnchangeVal}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-email ltn__custom-icon">
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                        required
                        onChange={OnchangeVal}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item">
                      <select
                        className="nice-select"
                        name="service"
                        required
                        onChange={OnchangeVal}
                      >
                        <option value="">Select Service Type</option>
                        <option value="Property Management">
                          Property Management
                        </option>
                        <option value="Mortgage Service">
                          Mortgage Service
                        </option>
                        <option value="Consulting Service">
                          Consulting Service
                        </option>
                        <option value="Home Buying">Home Buying</option>
                        <option value="Home Selling">Home Selling</option>
                        <option value="Escrow Services">Escrow Services</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-item input-item-phone ltn__custom-icon">
                      <input
                        type="number"
                        name="phone"
                        placeholder="Mobile Number*"
                        required
                        onChange={OnchangeVal}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-item input-item-textarea ltn__custom-icon">
                  <textarea
                    name="message"
                    placeholder="Enter message"
                    defaultValue={""}
                    required
                    onChange={OnchangeVal}
                  />
                </div>
                <div className="btn-wrapper mt-0">
                  <button
                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                    type="submit"
                  >
                    {loading ? "get a free service" : "Wait Sending ...."}
                  </button>
                </div>
                <p className="form-messege mb-0 mt-20" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
