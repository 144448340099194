import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class AboutV4 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img
                  src={publicUrl + "assets/img/others/13.png"}
                  alt="About Us Image"
                />
                <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                  <div className="ltn__video-img ltn__animation-pulse1">
                    <img
                      src={publicUrl + "assets/img/others/8.png"}
                      alt="video popup bg image"
                    />
                    <a
                      className="ltn__video-icon-2 ltn__video-icon-2-border---"
                      href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0"
                      data-rel="lightcase:myCollection"
                    >
                      <i className="fa fa-play" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h1 className="section-subtitle section-subtitle-2 ltn__secondary-color fnt-16">
                    Lee Group About Us
                  </h1>

                  <p>
                    Lee Group Properties is a full-service real estate company
                    committed to providing clients with professional and
                    responsive service related to home search. Our team of
                    professionals is dedicated to helping our customers achieve
                    their real estate goals. We specialize in residential
                    property sales, rentals, architecture, interior decoration,
                    and property management, as well as commercial property
                    sales and leasing. With our extensive knowledge coupled with
                    35 years of experience in the housing real estate market, we
                    can provide you with the best advice and resources to make
                    your housing venture successful.
                  </p>
                  <p>
                    We understand that buying or selling a home can be a
                    challenging and often overwhelming task. That is why our
                    team is here to guide you through the entire process. From
                    helping you find the perfect property to negotiating a great
                    deal, we are here to make the process as stress-free as
                    possible. We take pride in offering personalized service and
                    being available to answer all of your questions.
                  </p>
                  <p>
                    Our commitment to our clients does not end once we complete
                    a transaction. As a realtor, we are devoted to providing
                    ongoing support and assistance to ensure that you have a
                    positive experience with Lee Group Properties. Whenever you
                    buy a house from us, it is not just a sale but a lasting
                    impression. We invite you to contact us today and let us
                    show you why Lee Group Properties is the top realty promoter
                    around this area.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <ul className="ltn__list-item-half clearfix">
                  <li>
                    <i className="flaticon-home-2" />
                    Smart Home Design
                  </li>
                  <li>
                    <i className="flaticon-mountain" />
                    Beautiful Scene Around
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <ul className="ltn__list-item-half clearfix">
                  <li>
                    <i className="flaticon-heart" />
                    Exceptional Lifestyle
                  </li>
                  <li>
                    <i className="flaticon-secure" />
                    Complete 24/7 Security
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutV4;
