import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import sanityClient from "../../lib/client";
import BlockContent from "@sanity/block-content-to-react";
import { format } from "date-fns";
import RelatedProduct from "./RelatedProduct";
import RelatedBlog from "./RelatedBlog";
import Dropmsg from "./Dropmsg";
import SocialLink from "./SocialLink";

const ShopDetails = () => {
  const [singleProject, setSingleProject] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
             }
           },
           imageGallery[]{
            asset->{
              _id,
              url
             }
           },
           textloc,
           publishedAt,
           location,
         body,
        
       }`,
        { slug }
      )
      .then((data) => setSingleProject(data[0]))
      .catch(console.error);
  }, [slug]);

  let publicUrl = process.env.PUBLIC_URL + "/";
  if (!singleProject)
    return (
      <div>
        <h2 className="txt-center">Loading...</h2>
      </div>
    );
  return (
    <div className="ltn__shop-details-area pb-10">
      <div className="container">
        <img
          src={singleProject.mainImage.asset.url}
          alt="banner"
          style={{ width: "100%", paddingBottom: "50px" }}
        />
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
              <div className="ltn__blog-meta">
                <ul>
                  <li className="ltn__blog-category">
                    <Link className="bg-orange" to="#">
                      For Sales
                    </Link>
                  </li>
                  {singleProject.textloc && (
                    <li className="ltn__blog-date">
                      <i className="far fa-calendar-alt" />
                      {format(
                        new Date(singleProject.publishedAt),
                        " MMMM, dd, yyyy"
                      )}
                    </li>
                  )}
                </ul>
              </div>
              <h1>{singleProject.title}</h1>
              {singleProject.textloc && (
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  {singleProject.textloc}
                </label>
              )}

              <h4 className="title-2">Description</h4>
              {singleProject.body ? (
                <BlockContent
                  blocks={singleProject.body}
                  projectId={sanityClient.projectId}
                  dataset={sanityClient.dataset}
                />
              ) : (
                <p>Loading...</p>
              )}

              {singleProject.imageGallery && (
                <>
                  <h4 className="title-2">From Our Gallery</h4>
                  <div className="ltn__property-details-gallery mb-30">
                    <div className="row">
                      {singleProject.imageGallery.map((img, ind) => (
                        <div className="col-md-6" key={ind}>
                          <a
                            href={img.asset.url}
                            data-rel="lightcase:myCollection"
                          >
                            <img
                              className="mb-30"
                              src={img.asset.url}
                              alt="Image"
                            />
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}

              <h4 className="title-2 mb-10">Amenities</h4>
              <div className="property-details-amenities mb-60">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="ltn__menu-widget">
                      <ul>
                        <li>
                          <label className="checkbox-item">
                            Air Conditioning
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            Gym
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            Microwave
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            Swimming Pool
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            WiFi
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="ltn__menu-widget">
                      <ul>
                        <li>
                          <label className="checkbox-item">
                            Barbeque
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            Recreation
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            Microwave
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            Basketball Cout
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            Fireplace
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="ltn__menu-widget">
                      <ul>
                        <li>
                          <label className="checkbox-item">
                            Refrigerator
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            Window Coverings
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            Washer
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            24x7 Security
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                        <li>
                          <label className="checkbox-item">
                            Indoor Game
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="title-2">Property Video</h4>
              <div
                className="ltn__video-bg-img ltn__video-popup-height-500 bg-overlay-black-50 bg-image mb-60"
                style={{
                  background: `url(${singleProject.mainImage.asset.url}) no-repeat`,
                  backgroundSize: "cover",
                }}
              >
                <a
                  className="ltn__video-icon-2 ltn__video-icon-2-border---"
                  href="https://www.youtube.com/embed/eWUxqVFBq74?autoplay=1&showinfo=0"
                  data-rel="lightcase:myCollection"
                >
                  <i className="fa fa-play" />
                </a>
              </div>
              {singleProject.location && (
                <>
                  <h4 className="title-2">Location </h4>
                  <div className="property-details-google-map mb-60">
                    <iframe
                      src={singleProject.location}
                      width="100%"
                      height="100%"
                      frameBorder={0}
                      allowFullScreen
                      aria-hidden="false"
                      tabIndex={0}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
              <Dropmsg title={singleProject.title} pname={"Project"} />
              <RelatedProduct />
              <RelatedBlog />
              <SocialLink />

              {/* Banner Widget */}
              <div className="widget ltn__banner-widget d-none go-top">
                <Link to="/shop">
                  <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                </Link>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopDetails;
