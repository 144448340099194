import React, { Component } from "react";

class Social extends Component {
  render() {
    // let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__social-media">
        <ul>
          <li>
            <a
              href="https://www.facebook.com/LEEGROUPCBE"
              target="_blank"
              title="Facebook"
            >
              <i className="fab fa-facebook-f" />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/leegroupproperties"
              target="_blank"
              title="linkedin"
            >
              <i className="fab fa-linkedin" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/leegroupcbe/"
              target="_blank"
              title="Instagram"
            >
              <i className="fab fa-instagram" />
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/@leegroup4529"
              target="_blank"
              title="youtube"
            >
              <i className="fab fa-youtube" />
            </a>
          </li>
          <li>
            <a
              href="https://wa.me/917092070920/?text=Hi Lee Group"
              target="_blank"
              title="whatsapp"
            >
              <i className="fab fa-whatsapp" />
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Social;
