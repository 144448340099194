import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class FeaturesV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    let customClass = this.props.customClass ? this.props.customClass : "";

    return (
      <div className={customClass}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Our Services
                </h6>
                <h2 className="section-title">Our Main Focus</h2>
              </div>
            </div>
          </div>
          <div className="row ltn__custom-gutter--- justify-content-center go-top">
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <i className="flaticon-house" />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="#">Premium Real Estate Solutions</Link>
                  </h3>
                  <p>
                    Our knowledgeable team of agents is here to make your dream
                    home or property a reality. With years of experience and
                    in-depth market knowledge, you can trust us to find the
                    perfect fit for you.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <i className="flaticon-apartment" />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="#">Architecture & Interior Design Services</Link>
                  </h3>
                  <p>
                    We offer complete architecture and interior design services
                    with expert guidance on how to optimize your space for
                    maximum comfort and style. Our experienced designers can
                    help you bring your vision to life!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <i className="flaticon-official-documents" />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="#">Property Management Services</Link>
                  </h3>
                  <p>
                    Our team of dedicated property managers will ensure that all
                    aspects of managing your property are taken care of with
                    strict attention to detail. We specialize in both
                    residential and commercial properties.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FeaturesV1;
