import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class AboutV3 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pt-115 pb-100 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img
                  src={publicUrl + "assets/img/others/11.png"}
                  alt="About Us Image"
                />
                <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3 d-none">
                  <div className="ltn__video-img ltn__animation-pulse1">
                    <img
                      src={publicUrl + "assets/img/others/8.png"}
                      alt="video popup bg image"
                    />
                    <a
                      className="ltn__video-icon-2 ltn__video-icon-2-border---"
                      href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0"
                      data-rel="lightcase:myCollection"
                    >
                      <i className="fa fa-play" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-30">
                  <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                    Lee Group
                  </h6>
                  <h3 className="section-title">VISION</h3>
                  <p>
                    Lee Group properties focus on creating spaces where people
                    live and thrive. We are passionate about transforming
                    properties into lasting investments that create a sense of
                    community and home.
                  </p>
                  <p>
                    At Lee Group properties, we unlock the potential of real
                    estate to build vibrant communities. We strive to create
                    homes and neighborhoods with lasting impact and infinite
                    possibilities.
                  </p>

                  <h3 className="section-title" style={{ paddingTop: "20px" }}>
                    MISSION
                  </h3>
                  <p>
                    Our mission is to provide superior real estate services and
                    to create a lasting impression on all of our clients. We are
                    dedicated to finding innovative solutions to meet the unique
                    needs of each of our clients, and to consistently exceeding
                    their expectations.
                  </p>
                  <p>
                    Our mission is to bring clarity and simplicity to the real
                    estate process. We believe in providing unparalleled
                    customer service, utilizing the latest technology, and
                    delivering results that exceed our clients' goals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutV3;
