import React, { Component } from "react";
import Dropmsg from "../shop-components/Dropmsg";
import RelatedBlog from "../shop-components/RelatedBlog";
import RelatedProduct from "../shop-components/RelatedProduct";
import SocialLink from "../shop-components/SocialLink";

const Sidebar = ({ title }) => {
  return (
    <div className="col-lg-4 go-top">
      <aside className="sidebar-area blog-sidebar ltn__right-sidebar">
        <Dropmsg title={title} pname={"Blog"} />
        <RelatedProduct />
        <RelatedBlog />
        <SocialLink />
      </aside>
    </div>
  );
};

export default Sidebar;
