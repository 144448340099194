import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import sanityClient from "../../lib/client";
import BlockContent from "@sanity/block-content-to-react";
import { format } from "date-fns";
import Sidebar from "./sidebar";

const BlogDetails = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  const [blog, setBlog] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
            categories,
            mainImage{
              asset->{
                _id,
                url
               }
             },
             publishedAt,
             smalldes,
           body,
        
       }`,
        { slug }
      )
      .then((data) => setBlog(data[0]))
      .catch(console.error);
  }, [slug]);
  if (!blog)
    return (
      <div>
        <h2 className="txt-center">Loading...</h2>
      </div>
    );
  return (
    <div className="ltn__page-details-area ltn__blog-details-area mb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="ltn__blog-details-wrap">
              <div className="ltn__page-details-inner ltn__blog-details-inner">
                <div className="ltn__blog-meta">
                  <ul>
                    <li className="ltn__blog-category">
                      <Link to="#">{blog.categories}</Link>
                    </li>
                  </ul>
                </div>
                <h1 className="ltn__blog-title">{blog.title}</h1>
                <img
                  src={blog.mainImage.asset.url}
                  alt="Image"
                  style={{ width: "100%", paddingBottom: "20px" }}
                />
                <div className="ltn__blog-meta">
                  <ul>
                    <li className="ltn__blog-date">
                      <i className="far fa-calendar-alt" />
                      {format(new Date(blog.publishedAt), " MMMM, dd, yyyy")}
                    </li>
                  </ul>
                </div>
                <BlockContent
                  blocks={blog.body}
                  projectId="25z6lxn7"
                  dataset="production"
                />

                <blockquote>
                  <h6 className="ltn__secondary-color mt-0">BY LEE GROUP</h6>
                  The way we see it, real wealth means having the money and the
                  freedom to live life on your own terms.
                </blockquote>
              </div>
            </div>
          </div>
          <Sidebar title={blog.title} pname={"Blog"} />
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
