import React from "react";
import { useState } from "react";
import swal from "sweetalert";

const Dropmsg = ({ title, pname }) => {
  const [inputVal, setInputVal] = useState({});
  const [loading, setloading] = useState(true);

  const OnchangeVal = (event) => {
    setInputVal({ ...inputVal, [event.target.name]: event.target.value });
    console.log(inputVal);
  };

  const Onsubmit = (e) => {
    e.preventDefault();
    var phoneNo = inputVal.phone;
    var phoneVal = /^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/;
    if (!phoneNo.match(phoneVal)) {
      alert("Please provide valid phone number");
    } else {
      e.target.reset();
      setloading(false);
      window.Email.send({
        Host: "smtp.elasticemail.com",
        Username: "contact@leegroup.in",
        Password: "54438BD6520B636C0FC9EAA221702133E3E7",
        To: "contact@leegroup.in",
        From: "bluemount251095@gmail.com",
        Subject: `${pname} Form - Enquiry`,
        Body: `<b>Name :</b> ${inputVal.name} <br/><br/> <b>Email :</b> ${inputVal.email} <br/><br/> <b>Phone No : </b>${inputVal.phone} <br/><br/> <b>Message :</b> ${inputVal.message} <br/><br/> <b>${pname} title : </b>${title} `,
      }).then((message) => {
        console.log(message);
        if (message === "OK") {
          swal({
            title: "Email Sent Successfully",
            text: "Thank you for getting in touch!",
            icon: "success",
            button: "OK",
          });
          setloading(true);
        } else {
          swal({
            title: "Something Error!",
            text: "Please try aftor sometime",
            icon: "error",
            button: "Ok",
          });
          setloading(true);
        }
      });
    }
  };
  return (
    <>
      <div className="widget ltn__form-widget">
        <h4 className="ltn__widget-title ltn__widget-title-border-2">
          Drop Messege For Feedback
        </h4>
        <form onSubmit={Onsubmit}>
          <input
            type="text"
            name="name"
            placeholder="Your Name*"
            required
            onChange={OnchangeVal}
          />

          <input
            type="number"
            name="phone"
            placeholder="Mobile Number*"
            required
            onChange={OnchangeVal}
          />
          <input
            type="text"
            name="email"
            placeholder="Your e-Mail*"
            required
            onChange={OnchangeVal}
          />
          <textarea
            name="message"
            placeholder="Write Message..."
            required
            onChange={OnchangeVal}
          />
          <button type="submit" className="btn theme-btn-1">
            {loading ? "Send Messege" : "Wait Sending..."}
          </button>
        </form>
      </div>
    </>
  );
};

export default Dropmsg;
