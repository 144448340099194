import React, { Component, useEffect, useState } from "react";
import sanityClient from "../../lib/client";
import BlockContent from "@sanity/block-content-to-react";

import { Link } from "react-router-dom";
import parse from "html-react-parser";

const AboutV2 = () => {
  const [viewproperty, setProperty] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'property']{
          title,         
          mainImage{
            asset->{
              _id,
              url
             }
           },
           imageGallery[]{
            asset->{
              _id,
              url
             }
           },          
         body,
        
       }`
      )
      .then((data) => setProperty(data))
      .catch(console.error);
  }, []);
  let publicUrl = process.env.PUBLIC_URL + "/";
  if (!viewproperty) {
    return (
      <div>
        <h2 className="txt-center">Loading...</h2>
      </div>
    );
  }
  return (
    <div className="ltn__about-us-area pt-120 pb-90 ">
      {viewproperty &&
        viewproperty.map((item, index) => (
          <div className="container" key={index}>
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="about-us-info-wrap">
                  <div className="section-title-area ltn__section-title-2--- mb-30">
                    {/* <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color"></h6> */}
                    <h2 className="section-title">Today Sells Properties</h2>
                    <BlockContent
                      blocks={item.body}
                      projectId={sanityClient.projectId}
                      dataset={sanityClient.dataset}
                    />
                    {/* <p>
                      Customized Build Starts From Rs. 1900/ Enquire us for
                      unlimited response.
                    </p>
                    <ul className="ltn__list-item-1 ltn__list-item-1-before clearfix">
                      <li> Modern & comfortable house for sale</li>
                      <li>
                        Plots starts from <b>7.99 Lakhs / Cent</b>
                      </li>
                      <li>
                        On going projects <b>kottai privu, Karanadai</b>
                      </li>
                      <li>
                        More information call us <b>+91 70920 70920</b>
                      </li>
                    </ul> */}
                  </div>

                  <ul className="ltn__list-item-2 ltn__list-item-2-before ltn__flat-info">
                    <li>
                      <span>
                        2, 3, 4 <i className="flaticon-bed" />
                      </span>
                      Bedrooms
                    </li>
                    <li>
                      <span>
                        2, 3, 4 <i className="flaticon-clean" />
                      </span>
                      Bathrooms
                    </li>
                    <li>
                      <span>
                        <i className="flaticon-car" />
                      </span>
                      Car parking
                    </li>
                  </ul>
                  <ul className="ltn__list-item-2 ltn__list-item-2-before--- ltn__list-item-2-img mb-50">
                    {item.imageGallery.map((gal, galindex) => (
                      <li key={galindex} className="imgWd">
                        <a
                          href={gal.asset.url}
                          data-rel="lightcase:myCollection"
                        >
                          <img src={gal.asset.url} alt="Image" />
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 align-self-center">
                <div className="about-us-img-wrap about-img-right">
                  <img src={item.mainImage.asset.url} alt="About Us Image" />
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default AboutV2;
