import React, { useEffect, useState } from "react";
import sanityClient from "../../lib/client";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const Testimonial = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [testi, setTesti] = useState([]);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "testimonial"]{
          title,       
          gender,
          designation,
          msg,
      }`
      )
      .then((data) => setTesti(data))
      .catch(console.error);
  }, []);

  return (
    <div
      className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70"
      data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                Our Testimonial
              </h6>
              <h2 className="section-title">Clients Feedback</h2>
            </div>
          </div>
        </div>
        <div className="row ltn__testimonial-slider-4-activee sld-hr-act slick-arrow-1">
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={8000}
            keyBoardControl={true}
            customTransition="all 0.8"
            slidesToSlide={1}
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            rewindWithAnimation={true}
          >
            {testi &&
              testi.map((tst, index) => (
                <div className="col-lg-12" key={index}>
                  <div className="ltn__testimonial-item ltn__testimonial-item-7 ht-lap">
                    <div className="ltn__testimoni-info">
                      <p>
                        <i className="flaticon-left-quote-1" />
                        {" " + tst.msg}
                      </p>
                      <div className="ltn__testimoni-info-inner">
                        <div className="ltn__testimoni-img">
                          {tst.gender && tst.gender === "male" ? (
                            <img
                              src={
                                publicUrl + "assets/img/testimonial/male.png"
                              }
                              alt="#"
                            />
                          ) : (
                            <img
                              src={
                                publicUrl + "assets/img/testimonial/female.png"
                              }
                              alt="#"
                            />
                          )}
                        </div>
                        <div className="ltn__testimoni-name-designation">
                          <h5>{tst.title}</h5>
                          <label>{tst.designation}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
