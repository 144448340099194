import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import sanityClient from "../../lib/client";
import { format } from "date-fns";

const RelatedBlog = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'post'][0..3]{
            title,
            slug,
            categories,
            mainImage{
              asset->{
                _id,
                url
               }
             },
             publishedAt,
             smalldes,
           body,
          
         }`
      )
      .then((data) => setBlog(data))
      .catch(console.error);
  }, []);
  return (
    <>
      <div className="widget ltn__popular-post-widget go-top">
        <h4 className="ltn__widget-title ltn__widget-title-border-2">
          Leatest Blogs
        </h4>
        <ul>
          {blog &&
            blog.map((blog, index) => (
              <li key={index}>
                <div className="popular-post-widget-item clearfix">
                  <div className="popular-post-widget-img">
                    <Link to={`/blogs/${blog.slug.current}`}>
                      <img
                        style={{ borderRadius: "5px" }}
                        src={blog.mainImage.asset.url}
                        alt="#"
                      />
                    </Link>
                  </div>
                  <div className="popular-post-widget-brief">
                    <h6>
                      <Link to={`/blogs/${blog.slug.current}`}>
                        {blog.title}
                      </Link>
                    </h6>
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <a href="#">
                            <i className="far fa-calendar-alt" />
                            {format(
                              new Date(blog.publishedAt),
                              " MMMM, dd, yyyy"
                            )}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default RelatedBlog;
