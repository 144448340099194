import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Switch,
} from "react-router-dom";
import HomeV1 from "./components/home-v1";
import About from "./components/about";
import Service from "./components/service";
import Portfolio from "./components/portfolio";
import Error from "./components/404";
import ShopGrid from "./components/shop-grid";
import ProdductDetails from "./components/product-details";
import BlogGrid from "./components/blog-grid";
import BlogDetails from "./components/blog-details";
import Contact from "./components/contact";
import ScrollToTop from "./components/helper/ScrollToTop";

class Root extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop />
        <div>
          <Switch>
            <Route exact path="/" component={HomeV1} />
            <Route path="/about" component={About} />
            <Route path="/service" component={Service} />
            <Route path="/gallery" component={Portfolio} />
            <Route path="/project/:slug" component={ProdductDetails} />
            <Route path="/projects" component={ShopGrid} />
            <Route path="/blog" component={BlogGrid} />
            <Route path="/blogs/:slug" component={BlogDetails} />
            <Route path="/contact" component={Contact} />
            <Route path="*" component={Error} />

            {/* <Route path="/home-v2" component={HomeV2} />
            <Route path="/home-v3" component={HomeV3} />
            <Route path="/home-v4" component={HomeV4} />
            <Route path="/home-v5" component={HomeV5} />
            <Route path="/home-v6" component={HomeV6} />
            <Route path="/home-v7" component={HomeV7} />
            <Route path="/home-v8" component={HomeV8} />
            <Route path="/home-v9" component={HomeV9} />
            <Route path="/home-v10" component={HomeV10} /> */}

            {/* <Route path="/service-details" component={ServiceDetails} /> */}
            {/* <Route path="/portfolio-v2" component={PortfolioV2} /> */}
            {/* <Route path="/portfolio-details" component={PortfolioDetails} /> */}
            {/* <Route path="/team" component={Team} /> */}
            {/* <Route path="/team-details" component={TeamDetails} /> */}
            {/* <Route path="/faq" component={Faq} /> */}
            {/* <Route path="/coming-soon" component={ComingSoon} /> */}

            {/* <Route path="/location" component={Location} /> */}
            {/* <Route path="/shop" component={Shop} /> */}
            {/* <Route path="/shop-left-sidebar" component={ShopLeftSidebar} />
            <Route path="/shop-right-sidebar" component={ShopRightSidebar} /> */}

            {/* blog */}

            {/* <Route path="/blog-left-sidebar" component={BlogLeftSidebar} />
            <Route path="/blog-right-sidebar" component={BlogRightSidebar} /> */}
            {/* <Route path="/blog1" component={Blog} /> */}

            {/* <Route path="/cart" component={Cart} />
            <Route path="/checkout" component={Checkout} />
            <Route path="/my-account" component={MyAccount} />
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/add-listing" component={AddListing} />
            <Route path="/wishlist" component={Wishlist} />
            <Route path="/order-tracking" component={OrderTracking} />
            <Route path="/history" component={History} /> */}
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById("quarter"));
