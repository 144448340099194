import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import ContactInfo from "./section-components/contact-info";
import ContactForm from "./section-components/contact-form";
import Map from "./section-components/map";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import { Helmet } from "react-helmet";

const ContactV1 = () => {
  const Curl = window.location.href;
  return (
    <>
      <Helmet>
        <title>Contact Us - Lee group </title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Call us to talk with one of our agents and arrange a time to visit the site if you'd like to know more about our real estate service."
        />
        <meta
          name="keywords"
          content="Property for sale in Coimbatore,Residential land for sale, Kottiprivu plot sale Press colony plot sale Mathampalyam plot sale"
        />
        <meta property="og:title" content="Contact Us - Lee group " />
        <meta
          property="og:description"
          content="Call us to talk with one of our agents and arrange a time to visit the site if you'd like to know more about our real estate service. "
        />
        <meta property="og:url" content={Curl} />
        <meta property="og:site_name" content="Lee Gorup" />
        <link rel="canonical" href={Curl} />
      </Helmet>
      <div>
        <Navbar />
        <PageHeader headertitle="Contact Us" subheader="Contact" />
        <ContactInfo />
        <ContactForm />
        <Map />
        <CallToActionV1 />
        <Footer />
      </div>
    </>
  );
};

export default ContactV1;
