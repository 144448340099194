import React, { useEffect, useState } from "react";
import sanityClient from "../../lib/client";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
//import { Carousel } from "@trendyol-js/react-carousel";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { format } from "date-fns";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const ProductSliderV1 = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [testi, setTesti] = useState([]);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "project"]{
          title,
          slug,
          smalldes,
          mainImage{
            asset->{
              _id,
              url
             }
           },
           imageGallery[]{
            asset->{
              _id,
              url
             }
           },
           textloc,
           publishedAt,       
         
      }`
      )
      .then((data) => setTesti(data))
      .catch(console.error);
  }, []);
  return (
    <div>
      <div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-90 plr--7">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Properties
                </h6>
                <h2 className="section-title">Featured Listings</h2>
              </div>
            </div>
          </div>
          <div className="row ltn__product-slider-item-four-active-full-widthh sld-hr-act slick-arrow-11">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={8000}
              keyBoardControl={true}
              customTransition="all 0.8"
              slidesToSlide={1}
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              rewindWithAnimation={true}
            >
              {testi.map((tst, index) => (
                <div className="col-lg-12" key={index} id={"blgsld" + index}>
                  <div className="ltn__product-item ltn__product-item-4 text-center---">
                    <div className="product-img go-top">
                      <Link to={`/project/${tst.slug.current}`}>
                        <img
                          src={tst.mainImage.asset.url}
                          alt="#"
                          style={{ width: "100%", height: " 275px" }}
                        />
                      </Link>
                      <div className="product-badge">
                        <ul>
                          <li className="sale-badge bg-green">For Sales</li>
                        </ul>
                      </div>
                      <div className="product-img-location-gallery">
                        <div className="product-img-location">
                          <ul>
                            <li>
                              <Link to={`/project/${tst.slug.current}`}>
                                <i className="flaticon-pin" /> {tst.textloc}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="product-info">
                      <h2 className="product-title go-top">
                        <Link to={`/project/${tst.slug.current}`}>
                          {tst.title.substr(0, 31) + "..."}
                        </Link>
                      </h2>
                      <div className="product-description">
                        <p>{tst.smalldes.substr(0, 160) + "..."}</p>
                      </div>
                    </div>
                    <div className="product-info-bottom">
                      <div
                        className="ltn__blog-meta"
                        style={{ marginBottom: "0px" }}
                      >
                        <ul>
                          <li className="ltn__blog-date">
                            <i className="far fa-calendar-alt" />
                            {format(
                              new Date(tst.publishedAt),
                              " MMMM, dd, yyyy"
                            )}
                          </li>
                        </ul>
                      </div>
                      <div className="ltn__blog-btn">
                        <Link to={`/project/${tst.slug.current}`}>
                          Read more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSliderV1;
