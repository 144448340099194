import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class VideoV4 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    const VIDEO_WIDTH = 1920;
    const VIDEO_HEIGHT = 1080;
    return (
      <div className="ltn__slider-area ltn__slider-4 position-relative ltn__primary-bg">
        <div className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active">
          {/* HTML5 VIDEO */}
          {/* <iframe
            id="myVideo"
            className="bannerVd"
            src="https://www.youtube.com/embed/Yj2iELI6OeI?controls=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=Yj2iELI6OeI"
          ></iframe> */}

          <video autoPlay muted id="myVideo">
            <source src={publicUrl + "assets/media/4.mp4"} type="video/mp4" />
          </video>
          {/* <div className="video-container">
            <iframe src="https://www.youtube.com/embed/Yj2iELI6OeI?controls=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=Yj2iELI6OeI"></iframe>
          </div> */}
          <div
            className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-7 bg-image--- bg-overlay-theme-black-10---"
            data-bs-bg="img/slider/41.jpg"
          >
            <div className="ltn__slide-item-inner text-center">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 align-self-center">
                    <div className="slide-item-info">
                      <div className="slide-item-info-inner ltn__slide-animation">
                        <h6 className="slide-sub-title white-color animated">
                          <span>
                            <i className="fas fa-home" />
                          </span>{" "}
                          Lee Group
                        </h6>
                        <h2 className="slide-title text-uppercase white-color animated ">
                          Find Your Dream <br /> House By Us
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="video-background">
            <iframe
              width={VIDEO_WIDTH}
              height={VIDEO_HEIGHT}
              src="https://www.youtube.com/embed/D_vOqkEgmY0?autoplay=1&controls=0&mute=1&loop=1&modestbranding=1&showinfo=0&start=50&enablejsapi=1&&widgetid=3"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          </div> */}
        </div>
      </div>
    );
  }
}

export default VideoV4;
