import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import ShogGrid from "./shop-components/shop-grid-v1";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import { Helmet } from "react-helmet";

const ShopGrid_V1 = () => {
  const Curl = window.location.href;
  return (
    <>
      <Helmet>
        <title>Purchase a home in a future project from Lee Projects </title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="With the help of our famous real estate business, find the ideal commercial property,Residential and luxury villas in Coimbatore."
        />
        <meta
          name="keywords"
          content="Commercial real estate in Coimbatore ,Veerapandi privu plot sale. Karamadai plot sale, New Projects in Coimbatore for Sale, Residential Projects in Coimbatore"
        />
        <meta
          property="og:title"
          content="Purchase a home in a future project from Lee Projects "
        />
        <meta
          property="og:description"
          content="With the help of our famous real estate business, find the ideal commercial property,Residential and luxury villas in Coimbatore."
        />
        <meta property="og:url" content={Curl} />
        <meta property="og:site_name" content="Lee Gorup" />
        <link rel="canonical" href={Curl} />
      </Helmet>
      <div>
        <Navbar />
        <PageHeader headertitle="Our Projects" />
        <ShogGrid />
        <CallToActionV1 />
        <Footer />
      </div>
    </>
  );
};

export default ShopGrid_V1;
