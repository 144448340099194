import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import StackGrid, { transitions } from "react-stack-grid";
import sizeMe from "react-sizeme";
import sanityClient from "../../lib/client";

const PortfolioV1 = ({ size }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [allGalleryData, setAllGallery] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "gallery"]{
        title,       
        imageGallery[]{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllGallery(data))
      .catch(console.error);
  }, []);

  return (
    <div className="ltn__gallery-area mb-120">
      <div className="container">
        {/* (ltn__gallery-info-hide) Class for 'ltn__gallery-item-info' not showing */}
        <div className=" row ltn__gallery-style-2 ltn__gallery-info-hide---">
          <div className="ltn__gallery-sizer col-1" />
          {/* gallery-item */}

          <StackGrid
            columnWidth={size.width <= 768 ? "100%" : "33.33%"}
            appearDelay={0}
            monitorImagesLoaded={true}
            vendorPrefix={true}
            gutterHeight={7}
            itemComponent="article"
            component="section"
          >
            {allGalleryData &&
              allGalleryData.map((gallery, index) =>
                gallery.imageGallery.map((galimg, ind) => (
                  <div className="ltn__gallery-item gal-fil ">
                    <div className="ltn__gallery-item-inner">
                      <div className="ltn__gallery-item-img">
                        <a
                          href={galimg.asset.url}
                          data-rel="lightcase:myCollection"
                        >
                          <img src={galimg.asset.url} alt="Image" />
                          <span className="ltn__gallery-action-icon">
                            <i className="fas fa-search" />
                          </span>
                        </a>
                      </div>
                      <div className="ltn__gallery-item-info">
                        <h4 className="go-top">
                          <Link to="#">Lee Group </Link>
                        </h4>
                      </div>
                    </div>
                  </div>
                ))
              )}
          </StackGrid>
        </div>
      </div>
    </div>
  );
};

export default sizeMe()(PortfolioV1);
